<template>
  <div class="test">
    test1_2
    <v-textarea
      :label="$t('t.Note')"
      v-model="note"
      rows="4"
    />
  </div>
</template>

<script>
export default {

  data () {
    return {
      note: null
    }
  },
  mounted () {

  },
  methods: {

  }

}
</script>

<style lang="stylus" scoped>
.test
  display flex
  flex-grow 1
  overflow hidden
  flex-direction column
</style>
